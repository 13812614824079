import React from 'react'
import PropTypes from 'prop-types'
//import PreviewCompatibleImage from '../components/PreviewCompatibleImage'

const ServicePoints = ({ gridItems }) => (
  
  <div className="columns is-multiline">
    {gridItems.map(item => (
      <article className="is-child box ">
      <div key={item.head} className="column is-16 services">
        <div className="servicesheader">{item.head}</div>
        <div className="servicestext">{item.text}</div>  
      </div>
      </article>
    ))}
   </div>
 
  
)

ServicePoints.propTypes = {
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      head: PropTypes.string,
      text: PropTypes.string,
    })
  ),
}

export default ServicePoints